<template>
    <div>
        <card-header title="Registrations" icon="fa-suitcase-rolling"/>
        <card-toolbar>
            <button @click="$openCard('new-registration', {}, card)"><i class="fas fa-plus mr-2"/>New Registration</button>
        </card-toolbar>


        <card-body>
            <card-list v-if="!props.tripId">
                <subheader-list-item title="Recently Accessed" icon="fas fa-history"/>
                <registration-list-item
                    :card="card"
                    :registration="registration"
                    v-for="registration in registrations"
                    :active="child && child.definition.component === 'registration' && child.props.registrationId === registration.id.toString()"
                    @click="$openCard('registration', {registrationId: registration.id}, card)"
                    tasks
                />
                <subheader-list-item title="New Registrations" icon="fas fa-plus-square"/>
                <registration-list-item
                    :card="card"
                    :registration="registration"
                    v-for="registration in newRegistrations"
                    :active="child && child.definition.component === 'registration' && child.props.registrationId === registration.id.toString()"
                    @click="$openCard('registration', {registrationId: registration.id}, card)"
                    tasks
                />
            </card-list>
            <card-list v-else>
                <trip-list-item :card="card" :trip="trip"/>
                <separator-list-item/>
                <ContainerListItem padded class="pt-3 pb-3">
                    <b-field>
                        <b-select v-model="filter" icon="filter" expanded>
                            <option value="all">All Registrations</option>
                            <option value="participant">Participants</option>
                            <option value="waitlist">Wait List</option>
                            <option value="coach">Coaches</option>
                            <option value="staff">Staff</option>
                        </b-select>
                    </b-field>
                    <b-field>
                        <b-select v-model="sort" icon="sort-amount-down" expanded>
                            <option value="alpha">Alphabetical</option>
                            <option value="date">Registration Date</option>
                        </b-select>
                    </b-field>
                </ContainerListItem>
                <subheader-list-item title="Registrations" icon="fas fa-suitcase" :badge="filteredRegistrations.length"/>
                <registration-list-item
                    :card="card"
                    :registration="registration"
                    v-for="registration in filteredRegistrations"
                    :active="registration.active"
                    hide-trip
                    @click="$openCard('registration', {registrationId: registration.id}, card)"
                    tasks
                />
            </card-list>
        </card-body>

        <card-footer v-if="props.tripId">
            <strong>Total Registrations: </strong>{{ registrations.length }}
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardList from "../../TIER/components/CardList";
    import CardToolbar from "../../TIER/components/CardToolbar";
    import SearchListItem from "../../TIER/components/SearchListItem";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import SeparatorListItem from "../../TIER/components/SeparatorListItem";
    import NavigationListItem from "../../TIER/components/NavigationListItem";
    import TripListItem from "../../components/TripListItem";

    import {client as http} from '../../http_client';
    import CardBody from "../../TIER/components/CardBody";
    import RegistrationListItem from "../../components/RegistrationListItem";
    import CardFooter from "../../TIER/components/CardFooter";
    import CardListItem from "@/TIER/components/CardListItem";
    import ContainerListItem from "@/TIER/components/ContainerListItem";

    import async from "async";

    export default {
        name: "Users",
        props: ['card', 'child', 'props'],
        components: {ContainerListItem, CardListItem, CardFooter, RegistrationListItem, CardBody, TripListItem, NavigationListItem, SeparatorListItem, SubheaderListItem, SearchListItem, CardToolbar, CardList, CardHeader},
        data: function() {
            return {
                registrations: [],
                newRegistrations: [],
                archiveCount: 0,
                trip: {},
                filter: 'all',
                sort: 'alpha'
            };
        },
        computed: {
            filteredRegistrations: function() {
                let arr = [];

                if (this.filter === 'all') {
                    arr = [...this.registrations];
                } else {
                    for (let i = 0; i < this.registrations.length; i++) {
                        if (this.registrations[i].type === this.filter || this.registrations[i].second_type === this.filter) arr.push(this.registrations[i]);
                    }
                }

                if (this.sort === 'alpha') {
                    return arr.sort(function(a, b) {
                        let textA = a.user.last_name.toUpperCase();
                        let textB = b.user.last_name.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                } else {
                    return arr.sort(function(a, b) {
                        return a.user.created_at - b.user.created_at;
                    });
                }
            }
        },
        methods: {
            loadRegistrations: function(force) {
                this.$emit('loading', true);

                if (this.props.tripId) {
                    http.get('/api/trips/' + this.props.tripId + '/registrations/0/100', {force}).then(response => {
                        this.registrations = response.data.registrations;
                        this.$emit('loading', false);
                    });
                } else {
                    async.series([
                        (cb) => {
                            http.get('/api/registrations/recent/0/20', {ttl: 30, force}).then(response => {
                                this.registrations = response.data.registrations;
                                cb();
                            });
                        },
                        (cb) => {
                            http.get('/api/registrations/all/0/20', {ttl: 30, force}).then(response => {
                                this.newRegistrations = response.data.registrations;
                                cb();
                            });
                        }
                    ], err => {
                        this.$emit('loading', false);
                    });
                }
            },
            loadTrip: function() {
                http.get('/api/trips/' + this.props.tripId).then(response => {
                    this.trip = response.data;
                });
            },
            reload: function() {
                this.loadRegistrations(true);
            }
        },
        watch: {
            'props.tripId': function() {
                this.loadRegistrations();
                this.loadTrip();
            }
        },
        mounted() {
            this.loadRegistrations();

            if (this.props.tripId) {
                this.loadTrip();
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>
